<template>
  <div id="inputList">
    <el-dialog
      :title="inputListFormTitle"
      width="800px"
      :visible.sync="inputListDialogVisible"
      :close-on-click-modal="false"
      @close="inputListDialogClose"
    >
      <el-form
        ref="inputListFormRef"
        :model="inputListForm"
        :rules="inputListFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="新产品名称" prop="newProductName">
              <el-input v-model="inputListForm.newProductName" placeholder="请输入新产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="型号/规格" prop="spec">
              <el-input v-model="inputListForm.spec" placeholder="请输入型号/规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设计开发输入清单">
              <el-scrollbar style="height: 95.2px">
                <el-upload
                  ref="uploadRef"
                  action=""
                  :http-request="handleHttp"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  multiple
                >
                  <el-button size="small" type="primary">
                    点击上传
                  </el-button>
                </el-upload>
              </el-scrollbar>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="inputListForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="相关资料数量" prop="fileQuantity">
              <el-input v-model="inputListForm.fileQuantity" placeholder="请输入相关资料数量" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="编制人" prop="editor">
              <el-input v-model="inputListForm.editor" placeholder="请输入编制人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编制日期" prop="editTime">
              <el-date-picker v-model="inputListForm.editTime" placeholder="请选择编制日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核人" prop="auditor">
              <el-input v-model="inputListForm.auditor" placeholder="请输入审核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核日期" prop="auditTime">
              <el-date-picker v-model="inputListForm.auditTime" placeholder="请选择审核日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准人" prop="approver">
              <el-input v-model="inputListForm.approver" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准日期" prop="approvalTime">
              <el-date-picker v-model="inputListForm.approvalTime" placeholder="请选择批准日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="inputListDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="inputListFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="新产品名称">
        <el-input v-model="searchForm.newProductName" placeholder="请输入新产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="inputListPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="newProductName" label="新产品名称" />
      <el-table-column prop="spec" label="型号/规格" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="fileQuantity" label="相关资料数量" />
      <el-table-column prop="editor" label="编制人" />
      <el-table-column label="编制日期">
        <template slot-scope="scope">
          <span v-if="scope.row.editTime">{{ scope.row.editTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="auditor" label="审核人" />
      <el-table-column label="审核日期">
        <template slot-scope="scope">
          <span v-if="scope.row.auditTime">{{ scope.row.auditTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="编制日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalTime">{{ scope.row.approvalTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="inputListPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addInputList, deleteInputList, updateInputList, selectInputListInfo, selectInputListList } from '@/api/technology/inputList'
import { upload } from '@/api/main/file'

export default {
  data () {
    return {
      inputListDialogVisible: false,
      inputListFormTitle: '',
      inputListForm: {
        id: '',
        newProductName: '',
        spec: '',
        remarks: '',
        fileQuantity: '',
        editor: '',
        editTime: '',
        auditor: '',
        auditTime: '',
        approver: '',
        approvalTime: '',
        inputUrl: '',
        inputUrlName: ''
      },
      inputListFormRules: {
        newProductName: [{ required: true, message: '新产品名称不能为空', trigger: ['blur', 'change']}]
      },
      inputListPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        newProductName: ''
      },
      fileList: []
    }
  },
  created () {
    selectInputListList(this.searchForm).then(res => {
      this.inputListPage = res
    })
  },
  methods: {
    inputListDialogClose () {
      this.$refs.inputListFormRef.resetFields()
      this.$refs.uploadRef.clearFiles()
      this.fileList = []
    },
    inputListFormSubmit () {
      if (this.inputListFormTitle === '设计开发输入清单详情') {
        this.inputListDialogVisible = false
        return
      }
      this.$refs.inputListFormRef.validate(async valid => {
        if (valid) {
          this.inputListForm.inputUrl = this.fileList.map(file => file.path).join(',')
          this.inputListForm.inputUrlName = this.fileList.map(file => file.name).join(',')
          if (this.inputListFormTitle === '新增设计开发输入清单') {
            await addInputList(this.inputListForm)
          } else if (this.inputListFormTitle === '修改设计开发输入清单') {
            await updateInputList(this.inputListForm)
          }
          this.inputListPage = await selectInputListList(this.searchForm)
          this.inputListDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.inputListFormTitle = '新增设计开发输入清单'
      this.inputListDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteInputList(row.id)
        if (this.inputListPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.inputListPage = await selectInputListList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.inputListFormTitle = '修改设计开发输入清单'
      this.inputListDialogVisible = true
      this.selectInputListInfoById(row.id)
    },
    handleInfo (index, row) {
      this.inputListFormTitle = '设计开发输入清单详情'
      this.inputListDialogVisible = true
      this.selectInputListInfoById(row.id)
    },
    selectInputListInfoById (id) {
      selectInputListInfo(id).then(res => {
        this.inputListForm.id = res.id
        this.inputListForm.newProductName = res.newProductName
        this.inputListForm.spec = res.spec
        this.inputListForm.remarks = res.remarks
        this.inputListForm.fileQuantity = res.fileQuantity
        this.inputListForm.editor = res.editor
        this.inputListForm.editTime = res.editTime
        this.inputListForm.auditor = res.auditor
        this.inputListForm.auditTime = res.auditTime
        this.inputListForm.approver = res.approver
        this.inputListForm.approvalTime = res.approvalTime
        if (res.inputUrl) {
          const inputUrl = res.inputUrl.split(',')
          const inputUrlName = res.inputUrlName.split(',')
          this.fileList = []
          for (let i = 0; i < inputUrl.length; i++) {
            this.fileList.push({
              name: inputUrlName[i],
              path: inputUrl[i]
            })
          }
        }
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectInputListList(this.searchForm).then(res => {
        this.inputListPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectInputListList(this.searchForm).then(res => {
        this.inputListPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectInputListList(this.searchForm).then(res => {
        this.inputListPage = res
      })
    },
    handleHttp (file) {
      const data = new FormData()
      data.append('file', file.file)
      upload(data).then(res => {
        this.fileList.push({
          name: file.file.name,
          path: res,
          uid: file.file.uid
        })
      })
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    }
  }
}
</script>

<style>
#inputList .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
