import axios from '@/common/axios'
import qs from 'qs'

export function addInputList (data) {
  return axios({
    method: 'post',
    url: '/technology/input/add',
    data: qs.stringify(data)
  })
}

export function deleteInputList (id) {
  return axios({
    method: 'delete',
    url: '/technology/input/delete/' + id
  })
}

export function updateInputList (data) {
  return axios({
    method: 'put',
    url: '/technology/input/update',
    data: qs.stringify(data)
  })
}

export function selectInputListInfo (id) {
  return axios({
    method: 'get',
    url: '/technology/input/info/' + id
  })
}

export function selectInputListList (query) {
  return axios({
    method: 'get',
    url: '/technology/input/list',
    params: query
  })
}
